<template>
    <v-row 
        justify="center"
    >
        <div class=" col-12 col-sm-10 col-md-8 col-xl-6">
            <NotificationView></NotificationView>
        </div>
    </v-row>
</template>
<script>
import NotificationView from '@/components/notifications/notificationView'
export default {
    components: {NotificationView}
}
</script>